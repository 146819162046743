import React, { useState, useEffect, useCallback, useRef } from 'react'
import { partnerWorkoutCategoryListPromise } from 'api/PartnerWorkoutCategory'
import PartnerWorkoutCategoryListTable from './components/PartnerWorkoutCategoryListTable'
import PartnerWorkoutCategoryListToolbar from './components/PartnerWorkoutCategoryListToolbar'
import QS from 'qs'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { workoutCategoryListPromise } from 'api/WorkoutCategory'

const PartnerWorkoutCategoryList = props => {
  const [partnerWorkoutCategory, setPartnerWorkoutCategory] = useState([])
  const [workoutCategories, setWorkoutCategories] = useState([])
  const [params, setParams] = useState({})
  const ref = useRef()
  const location = useLocation()

  // start
  // load data
  useEffect(() => {
    const foo = QS.myParse(location.search, false)
    setParams(foo)
    workoutCategoryListPromise().then(resp => setWorkoutCategories(resp))
    partnerWorkoutCategoryListPromise(foo).then(resp => setPartnerWorkoutCategory(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = QS.myParse(location.search, false)
    const too = _.pickBy({ ...foo, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${QS.stringify(too)}`), delay)
  }, [location.search, props.history])

  function handleSearchChange (payload) {
    handleParamsChange(payload, 0)
  }
  // end

  return (
    <div className={'root'}>
      <div className={'content'}>
        <PartnerWorkoutCategoryListToolbar handleChange={handleSearchChange} params={params} {...props} />
        <br />
        <PartnerWorkoutCategoryListTable {...props} data={partnerWorkoutCategory} workoutCategories={workoutCategories} />
      </div>
    </div>
  )
}

export default PartnerWorkoutCategoryList