import { getApiClient } from './ApiClient'

export const whitelistListPromise = (params) => {
  return getApiClient()
    .get('/whitelist', { params })
    .then(resp => Promise.resolve(resp.data))
}

export const createWhitelistPromise = (body) => {
  return getApiClient()
    .post('/whitelist', { body })
    .then(resp => Promise.resolve(resp.data))
}

export const deleteWhitelistPromise = (id) => {
  return getApiClient()
    .delete(`/whitelist/${id}`)
    .then(resp => Promise.resolve(resp.data))
}
