import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import { Profile, SidebarNav } from './components'
import { ReactComponent as TargetArrowIcon } from 'icons/arrow-target.svg'
import * as Icon from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

export const pages = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <Icon.Dashboard />,
  },
  {
    title: 'User',
    href: '/users?emailFilter=1',
    icon: <Icon.People />,
    subItems: [{
      title: 'Report',
      href: '/user-reports',
      icon: <Icon.Note />,
    },{
      title: 'Feedback',
      href: '/user-feedbacks',
      icon: <Icon.Feedback />,
    },{
      title: 'Purchase',
      href: '/purchases',
      icon: <Icon.TextFields />,
    },{
      title: 'Admin Tool User',
      href: '/admins',
      icon: <Icon.Accessibility />,
    },{
      title: 'Find Similar',
      href: '/users/find-similar',
      icon: <Icon.SupervisorAccount />,
    },{
      title: 'User Health Access Token',
      href: '/user-health-access-tokens',
      icon: <Icon.Dehaze />,
    }]
  },
  {
    title: 'Marketing',
    href: '#',
    icon: <Icon.ShoppingBasket />,
    subItems: [{
      title: 'Promo Code',
      href: '/promos',
      icon: <Icon.LocalOffer />,
    }, {
      title: 'Banner Campaigns',
      href: '/feature-banner/campaigns',
      icon: <Icon.ViewCarousel />,
    }]
  },
  {
    title: 'Workout',
    href: '/workout-resources',
    icon: <Icon.SportsHandball />,
    subItems: [{
      title: 'Category',
      href: '/workout-categories',
      icon: <Icon.Category />,
    }, {
      title: 'Trending',
      href: '/workout-trendings',
      icon: <Icon.TrendingUp />,
    }, {
      title: 'Dailyfix',
      href: '/workout-dailyfixes',
      icon: <Icon.AccessibilityNew />
    }, {
      title: 'Equipment',
      href: '/workout-equipments',
      icon: <Icon.FitnessCenter />,
    }, {
      title: 'Partner',
      href: '/partner-workout-resources',
      icon: <Icon.Group />
    }, {
      title: 'Partner Category',
      href: '/partner-workout-categories',
      icon: <Icon.FilterVintage />
    }, {
      title: 'Version',
      href: '/workout-resource-versions',
      icon: <Icon.AccessTime />
    }, {
      title: 'Trainer',
      href: '/trainers',
      icon: <Icon.Accessibility />
    },
    {
      title: 'Move Resource',
      href: '/move-resources',
      icon: <Icon.ThumbUp />
    }, {
      title: 'Move Workout',
      href: '/move-workouts',
      icon: <Icon.ThumbUpOutlined />
    }, {
      title: 'Chapter',
      href: '/workout-chapters',
      icon: <Icon.ViewCarousel />
    }, {
      title: 'Segment Targeting',
      href: '/workout/segment-targeting',
      icon: <Icon.TrackChanges />
    }, {
      title: 'Quality Score Boost',
      href: '/workout/quality-score-boosts',
      icon: <Icon.FormatLineSpacing />
    }]
  },
  {
    title: 'Social',
    href: '#',
    icon: <Icon.Public />,
    subItems: [
      { title: 'Social Group', href: '/social-groups', icon: <Icon.Group /> },
      { title: 'Post', href: '/user-social-feed-post', icon: <Icon.ViewAgenda /> },
      { title: 'Comment', href: '/user-social-feed-comment', icon: <Icon.Comment /> },
      { title: 'User Social', href: '/user-social', icon: <Icon.Person /> },
      { title: 'Filter', href: '/filters', icon: <Icon.Filter /> },
      { title: 'Invite template', href: '/invite-templates', icon: <Icon.Subtitles /> },
      { title: 'Report', href: '/social-feed/reports', icon: <Icon.Note /> },
      { title: 'Badge', href: '/social-badges', icon: <Icon.Label /> },
      { title: 'User Badge', href: '/user-social-badges', icon: <Icon.Loyalty /> },
    ]
  },
  {
    title: 'Program',
    href: '/programs',
    icon: <Icon.AddShoppingCart />,
    subItems: [
      { title: 'Program Workout', href: '/program-workouts', icon: <Icon.SportsHandball /> },
      { title: 'Program Week', href: '/program-weeks', icon: <Icon.ViewWeek /> },
      { title: 'Program Nutrition', href: '/program-nutrition', icon: <Icon.LocalPizza /> },
      { title: 'Program Tool', href: '/program-tools', icon: <Icon.SquareFoot /> },
      { title: 'User Program', href: '/user-programs', icon: <Icon.Portrait /> },
    ]
  },
  {
    title: 'Product',
    href: '/products',
    icon: <Icon.Dns />,
    subItems: [
      { title: 'Feature Set', href: '/feature-sets', icon: <Icon.FeaturedPlayList /> },
      { title: 'Sku Category', href: '/sku-categories', icon: <Icon.Category /> },
      { title: 'Product Sku Relation', href: '/sku-feature-relations', icon: <Icon.Dehaze /> },
      { title: 'Feature Set Components', href: '/feature-set-components', icon: <Icon.FeaturedVideo /> },
    ]
  },
  {
    title: 'Course',
    href: '/courses',
    icon: <Icon.LaptopWindows />,
    subItems: [
      { title: 'Website', href: '/course-websites', icon: <Icon.Web /> },
      { title: 'Week', href: '/course-weeks', icon: <Icon.ViewWeek /> },
      { title: 'Day', href: '/course-videos', icon: <Icon.Today /> },
      { title: 'User Course', href: '/user-courses', icon: <Icon.EmojiPeople /> },
    ]
  },
  {
    title: 'Challenges',
    href: '/challenges',
    icon: <TargetArrowIcon />,
  },
  {
    title: 'Meal',
    href: '/meals',
    icon: <Icon.RestaurantMenu />,
    subItems: [{
      title: 'Ingredient',
      href: '/meal-ingredients',
      icon: <Icon.Cloud />,
    }, {
      title: 'Category',
      href: '/meal-categories',
      moduleName: 'mealCategory',
      icon: <Icon.Category />,
    }, {
      title: 'Plan',
      href: '/meal-plans',
      icon: <Icon.ChildCare />,
    }, {
      title: 'Ingredient Category',
      href: '/meal-ingredient/categories',
      icon: <Icon.Toys />,
    }, {
      title: 'Ingredient Preparation',
      href: '/meal-ingredient/preparations',
      icon: <Icon.BubbleChart />,
    }, {
      title: 'Ingredient Unit',
      href: '/meal-ingredient/units',
      icon: <Icon.AcUnit />,
    }, {
      title: 'Shopping List',
      href: '/meal-ingredient/category-groceries',
      icon: <Icon.ShoppingCart />,
    }, {
      title: 'Recipe Tag',
      href: '/recipe-tags',
      icon: <Icon.Bookmark />,
    }, {
      title: 'Recipe Tag Option',
      href: '/recipe-tag-options',
      icon: <Icon.Style />,
    }]
  },
  {
    title: 'Theme',
    href: '/themes',
    icon: <Icon.FilterVintage />,
    subItems: [{
      title: 'Theme Details',
      href: '/theme-details/item',
      icon: <Icon.Cloud />,
    }]
  },
  {
    title: 'Video',
    href: '#',
    icon: <Icon.Videocam />,
    subItems: [
      { title: 'Workout Resource', href: '/videos/upload', icon: <Icon.SportsHandball /> },
      { title: 'Move Workout Resource', href: '/videos/move-resource', icon: <Icon.SportsHockey /> },
      // { title: 'Workout Caption', href: '/videos/workout-caption', icon: <Subtitles /> },
      { title: 'Upload Other Video', href: '/course-video-upload', icon: <Icon.CloudUpload /> },
      { title: 'Video Metadata', href: '/videos/metadata', icon: <Icon.VideoLabel /> },
    ]
  },
  {
    title: 'FitOn Business',
    href: '#',
    icon: <Icon.Business />,
    subItems: [
      { title: 'User',  href: '/business/users', icon: <Icon.People /> },
      { title: 'Group',  href: '/business/groups', icon: <Icon.Group /> },
      { title: 'SMB Self-serve',  href: '/business/corporate-wellness', icon: <Icon.Apartment /> },
      { title: 'Link Accounts',  href: '/business/link-accounts', icon: <Icon.PeopleOutline /> },
      { title: 'Flex Merchant',  href: '/flex-network/merchants', icon: <Icon.GridOn /> },
      { title: 'Flex Card Events',  href: '/business/flex-card-events', icon: <Icon.BorderColor /> },
    ]
  },
  {
    title: 'Studio',
    href: '#',
    icon: <Icon.Category />,
    subItems: [
      { title: 'Foh Studio',  href: '/foh-studios', icon: <Icon.GridOn /> },
      { title: 'Flex Whitelist',  href: '/flex-whitelist', icon: <Icon.SentimentSatisfiedAlt /> },
      { title: 'Flex Blacklist',  href: '/flex-blacklist', icon: <Icon.SentimentVeryDissatisfied /> },
    ]
  },
  {
    title: 'Util',
    href: '#',
    icon: <Icon.Build />,
    subItems: [{
      title: 'Function Switch',
      href: '/function-switches',
      icon: <Icon.Functions />,
    }, {
      title: 'Whitelist',
      href: '/whitelist',
      icon: <Icon.SentimentSatisfiedAlt />,
    }, {
      title: 'Blacklist',
      href: '/request-blacklist',
      icon: <Icon.SentimentVeryDissatisfied />,
    }, {
      title: 'Subscription Status',
      href: '/subscription-status',
      icon: <Icon.Search />,
    }, {
      title: 'Build I18N Search',
      href: '/i18n/translation-item-relation',
      icon: <Icon.FindReplace />,
    // }, {
    //   title: 'Upload Center',
    //   href: '/upload-center',
    //   icon: <CloudUploadSharp />,
    // }, {
    // }, {
    //   title: 'SKU Management',
    //   href: '/products',
    //   icon: <ShoppingBasketSharp />,
    }
  ]
  },
  {
    title: 'Log',
    href: '/logs',
    icon: <Icon.Book />,
  },
  // {
  //   title: 'Setting',
  //   href: '/settings',
  //   icon: <SettingsIcon />,
  // },
]

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
