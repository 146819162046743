import { getApiClient } from './ApiClient'

export const userListPromise = (params) => {
  return getApiClient()
    .get('/user-list', { params })
    .then(resp => resp.data)
}

export const userDetailPromise = id => {
  return getApiClient()
    .get('/user', {
      params: { id: id },
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const updateUserPromise = user => {
  return getApiClient()
    .put('/user', {
      body: {
        id: user.id,
        role: user.role,
        city: user.city,
        email: user.email,
        phone: user.phone,
        active: user.active,
        avatar: user.avatar,
        ambassador: user.ambassador,
        countryCode: user.countryCode,
        countryOrState: user.country_or_state,
      },
    })
    .then(resp => {
      return Promise.resolve(resp.msg)
    })
}

export const resetPassword = (id, password) => {
  return getApiClient()
    .post('/reset-password', {
      body: {
        id,
        password
      },
    })
    .then(resp => {
      return Promise.resolve(resp.msg)
    })
}

export const userReportListPromise = (params) => {
  return getApiClient()
    .get('/user-reports', {
      params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const userReportDetailPromise = (id) => {
  return getApiClient()
    .get(`/user-reports/${id}`)
    .then(resp => resp.data)
}

export const userReportDeletePromise = (id, action) => {
  return getApiClient()
    .delete(`/user-reports/${id}`, { body: { action } })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const userFeedbackListPromise = (params) => {
  return getApiClient()
    .get('/user-feedbacks', {
      params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const grantPromoPromise = (params) => {
  return getApiClient()
    .post('/grant-promo', {
      body: { ...params },
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const addUserToSocialGroupPromise = body => {
  return getApiClient().post('/users/social-group/add', { body }).then(resp => resp.data)
}

export const removeUserFromSocialGroupPromise = body => {
  return getApiClient().delete('/users/social-group/remove', { body }).then(resp => resp.data)
}

export const addOrRemoveFriend = body => {
  return getApiClient().post('users/friend', { body }).then(resp => resp.data)
}

export const createUserPromise = body => {
  return getApiClient().post('users', { body }).then(resp => resp.data)
}

export const findSimilarUserPromise = body => {
  return getApiClient().post('/users/find-similar', { body }).then(resp => resp.data)
}

export async function getUserHealthAccessTokenListPromise (params) {
  return getApiClient()
    .get('/user-health-access-tokens', { params })
    .then(resp => resp.data)
}

export async function updateUserHealthAccessTokenPromise (body) {
  return getApiClient()
    .post('/user-health-access-tokens', { body })
    .then(resp => resp.data)
}
