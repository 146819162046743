import React, { useMemo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import { booleanString as boolStr } from 'common/usual'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import _ from 'lodash'

const PartnerWorkoutCategoryTable = props => {
  const { data, workoutCategories } = props

  const workoutCategoryMap = useMemo(() => {
    return _.keyBy(workoutCategories, 'id')
  }, [workoutCategories])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell>Workout Category</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data && data.map(category => (
                  <TableRow className={'table-row'} key={category.id} hover>
                    <TableCell>{category.id}</TableCell>
                    <TableCell>{category.partner && category.partner.name}</TableCell>
                    <TableCell>{workoutCategoryMap?.[category.workoutCategoryId]?.name}</TableCell>
                    <TableCell>{category.sort}</TableCell>
                    <TableCell>{boolStr(category.active)}</TableCell>
                    <TableCell>{formatDateTime(category.createdAt)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/partner-workout-category/edit/${category.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default PartnerWorkoutCategoryTable