import _ from 'lodash'
import { adminDetailPromise } from 'api/Admin'
import { getAdminId } from 'api/ApiClient'
import PermissionCode from 'assets/permissionCode.json'

let count = 0,
  permissions = []

export async function intiAdminPermission (id) {
  count = 10
  const adminId = id || getAdminId()
  if (!adminId) return
  
  const admin = await adminDetailPromise(adminId)
  if (!admin) throw new Error('admin not found')

  permissions = admin.permissions || []
}

export function checkPermission (code) {
  if (!code) return true
  // count--

  if (count <= 0) intiAdminPermission()

  return permissions.includes(code)
}

export function getPermissionObjByPath (path) {
  path = path?.split('?')[0]
  return PermissionCode.find(pc => pc.path === path)
}

export function getPermissionCodeByPath (path, type = 'list') {
  const foo = getPermissionObjByPath(path)
  if (!foo) return

  const types = [].concat(type)

  for (const t of types) {
    if (foo.codes[t]) return foo.codes[t]
  }
}

export function getCodesForList () {
  return _.uniq(_.compact(
    PermissionCode.filter(p => !p.notEditable).map(p => p.codes && p.codes.list)
  ))
}
