import _ from 'lodash'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import DeleteBtn from 'components/DeleteBtn'
import { formatDateTime } from 'common/usual'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, CardActions, TablePagination, Grid } from '@material-ui/core'
import { deleteWhitelistPromise } from 'api/Whitelist'

const ProgramTable = (props) => {
  const [pair, setPair] = useState({})
  const { data, page, types, loadData, onPageChange } = props

  useLayoutEffect(() => {
    types && setPair(_.invert(types))
  }, [types])

  const handleDelete = useCallback(id => {
    deleteWhitelistPromise(id)
      .then(loadData)
  }, [loadData])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data?.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{pair[record.type]}</TableCell>
                      <TableCell>{record.value}</TableCell>
                      <TableCell>{formatDateTime(record.created_at)}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={6}>
                            <DeleteBtn onClick={event => handleDelete(record.id)} />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={(data && data.count) || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramTable
