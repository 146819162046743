import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDateTime } from 'common/usual'
import { Share } from '@material-ui/icons'
import DeleteBtn from 'components/DeleteBtn'
import {
  Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, CardActions, TablePagination, Input, Button
} from '@material-ui/core'
import { deleteSocialFeedPostOrCommentPromise, shareSocialFeedPost, updateSocialFeedPostOrCommentPromise } from 'api/SocialFeed'
import './index.scss'
import PushpinIcon from 'icons/pushpin.svg'
import { SimpleModal } from 'components'
import { AutoCompleteNew } from 'components/Select'
import { SocialFeedPostAccountOptions, SocialFeedPostType } from 'common/enum'

const PostAndCommentTable = props => {
  const { data, page, history, onPageChange } = props
  const [records, setRecords] = useState([])
  const [isModalOn, setIsModalOn] = useState(false)
  const [shareBody, setShareBody] = useState({})

  useEffect(() => {
    setRecords(data)
  }, [data])

  const deleteSocialFeed = useCallback(record => {
    deleteSocialFeedPostOrCommentPromise({ id: record.id, kind: 'post' }).then(() => {
      record.active = false
      setRecords({ ...records })
    })
    
  }, [records])

  const toggleSocialFeedPostPin = useCallback(record => {
    const pin = !record.isPinned
    updateSocialFeedPostOrCommentPromise({ id: record.id, pin }).then(() => {
      record.isPinned = pin
      setRecords({ ...records })
    })
  }, [records])

  const handleModalClose = useCallback(() => {
    setIsModalOn(false)
    setShareBody({})
  }, [])

  const whenShareClicked = useCallback((socialPostId) => {
    setIsModalOn(!isModalOn)
    setShareBody({ ...shareBody, targetId: socialPostId, type: SocialFeedPostType.SharePost })
  }, [isModalOn, shareBody])

  const handleShareBodyChange = useCallback((key, value) => {
    setShareBody({ ...shareBody, [key]: value })
  }, [shareBody])

  const handleShare = useCallback(() => {
    shareSocialFeedPost(shareBody)
      .then(resp => {
        if (resp.status === 'OK') handleModalClose()
      })
  }, [handleModalClose, shareBody])

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Group ID</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {records.rows && records.rows.map(record => (
                  <TableRow className={'table-row'} key={record.id} hover onClick={() => { history.push(`/user-feed-post-or-comment/${record.id}?kind=post`) }}>
                    <TableCell>{record.id}</TableCell>
                    <TableCell>{record.user_id}</TableCell>
                    <TableCell>{record.group_id}</TableCell>
                    <TableCell>{record.description}</TableCell>
                    <TableCell>{formatDateTime(record.created_at)}</TableCell>
                    <TableCell onClick={(event) => event.stopPropagation()} >
                      { record.active && <Share className={'cursor-pointer'} onClick={ () => whenShareClicked(record.id) } /> }
                      &nbsp;
                      {
                        !record.active ? 'DELETED' : <DeleteBtn onClick={(e) => {
                          e.stopPropagation()
                          deleteSocialFeed(record)
                        }} />
                      }
                      &nbsp;
                      {
                        record.kind === 'post' &&
                          <img alt='pushpin' src={PushpinIcon} height={'24px'} width={'24px'} class={record.isPinned && 'rotate-seven'} onClick={e => {
                            e.stopPropagation()
                            toggleSocialFeedPostPin(record)
                          }} />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={records.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>

      <SimpleModal open={isModalOn} handleClose={handleModalClose}>
        <div style={{ width: '500px', padding: '20px 0 10px 0' }}>
          {/* <div>{ JSON.stringify(shareBody) }</div> */}
          <AutoCompleteNew placeholder='target user type'
            value={shareBody.userId} options={SocialFeedPostAccountOptions} disableUnderline={false} inputLeft={true}
            onChange={(event, selected) => handleShareBodyChange('userId', selected && selected.value) } />
          <br />
          <Input placeholder='description' value={shareBody.description} className={'full-width'} onChange={(event) => handleShareBodyChange('description', event.target.value)} />
          <br /><br />
          <Button variant='contained' component='label' color='primary' size='small' onClick={ handleShare } disabled={!shareBody.userId}>
            Forward
          </Button>
        </div>
      </SimpleModal>
    </Card>
  )
}

export default PostAndCommentTable
