import _ from 'lodash'
import Table from './table'
import { Button, Grid } from '@material-ui/core'
import { checkPermission } from 'helpers/permission'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { getUtilConfigsPromise, updateUtilConfigPromise } from 'api/Util'

const FlexBlacklist = props => {
  const { name } = props
  const [id, setId] = useState(0)
  const [data, setData] = useState({})
  const [added, setAdded] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const loadData = useCallback(() => {
    getUtilConfigsPromise(name)
      .then(resp => {
        setId(resp[0].id)
        setData(JSON.parse(resp[0].value))
      })
  }, [name])

  useLayoutEffect(() => {
    loadData()
  }, [loadData])

  const handleDelete = useCallback((key, val) => {
    setData(prev => ({ ...prev, [key]: _.pull(prev[key], val) }))
  }, [])

  const handleClick = useCallback(() => {
    setAdded({})
    if (isEditing && id) {
      const keys = Object.keys(data)
      const payload = keys.reduce((res, key) => {
        return { ...res, [key]: _.uniq(data[key].concat(_(added[key]).split(',').filter(f => f).map(s => s.trim()).value())) }
      }, {})
      updateUtilConfigPromise(id, { value: JSON.stringify(payload) }).then(loadData)
    }

    setIsEditing(!isEditing)
  }, [added, id, isEditing, loadData, data])

  const handleChange = useCallback((key, str) => {
    setAdded(prev => ({ ...prev, [key]: str }))
  }, [])

  const handleCancel = useCallback(() => {
    setIsEditing(false)
  }, [])

  return (
    <div className={'root'}>
      <Grid container item xs={12} spacing={2}>
        <Grid item container xs={12} justifyContent='flex-end'>
          {
            isEditing && <Button className={'add-btn'} variant='contained' onClick={handleCancel}>
              Cancel
            </Button>
          }
          &nbsp;&nbsp;
          {
            checkPermission('120102') && <Button className={'add-btn'} color={isEditing ? 'secondary' : 'primary'} variant='contained' onClick={handleClick}>
              { isEditing ? 'Done' : 'Edit' }
            </Button>
          }
        </Grid>

        <Grid item xs={12}>
          <Table isEditing={isEditing} data={data} handleChange={handleChange} handleDelete={handleDelete}  />
        </Grid>
      </Grid>
    </div>
  )
}

export default FlexBlacklist
