import React from 'react'
import { Grid, TextField } from '@material-ui/core'

export default function Toolbar (props) {
  const { params, handleSearchChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField name='userId' label='User ID' fullWidth variant='outlined' size='small' className='background-color' value={params.user_id} onChange={event => handleSearchChange({ userId: event.target.value })} />
        </Grid>
      </Grid>
    </div>
  )
}
