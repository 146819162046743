import { ParamsForOption } from 'common/enum'
import { getApiClient } from './ApiClient'

export const workoutCategoryListPromise = (params) => {
  return getApiClient()
    .get('/workout-categories', { params })
    .then(resp => {
      return resp.data.map(wc => {
        const { senior, non_senior, female, male } = wc
        wc.original_name = wc.name

        wc.name += ' '
        if (senior ^ non_senior) wc.name += senior ? '(senior)' : '(non-senior)'
        if (female ^ male) wc.name += female ? '(female)' : '(male)'
        wc.name = wc.name.trim()

        return wc
      })
    })
}

export const workoutCategoryDetailPromise = (id) => {
  return getApiClient()
    .get(`/workout-category/${id}`)
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutCategoryShowingTypeListPromise = () => {
  return getApiClient()
    .get('/workout-category/showing-types', { params: ParamsForOption })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const workoutCategoryUpsertPromise = body => {
  return getApiClient()
    .post('/workout-category/upsert', { body })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}