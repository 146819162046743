import _ from 'lodash'
import Table from './table'
import Toolbar from './toolbar'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import { whitelistListPromise } from 'api/Whitelist'
import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react'
import { getEnumOptionsPromise } from 'api/Util'

const Whitelist = props => {
  const ref = useRef()
  const location = useLocation()
  const [data, setData] = useState({})
  const [types, setTypes] = useState({})
  const [params, setParams] = useState({ page: 1 })

  const loadData = useCallback(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    whitelistListPromise(too).then(setData)
  }, [location.search])

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  // load data
  useEffect(() => loadData(), [loadData])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }

  function handleSearchChange (payload, delay) {
    handleParamsChange(payload, delay)
  }
  // end

  useLayoutEffect(() => {
    getEnumOptionsPromise('AccessLogAndWhitelistTypes')
      .then(resp => setTypes(resp['AccessLogAndWhitelistTypes']))
  }, [])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar handleSearchChange={handleSearchChange} params={params} types={types} {...props} />
        <br />
        <Table {...props} data={data} types={types} page={params.page} loadData={loadData} onPageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default Whitelist
