import React, { useCallback } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import DeleteBtn from 'components/DeleteBtn'
import { updateUserHealthAccessTokenPromise } from 'api/User'
import { checkPermission } from 'helpers/permission'

const VideoResourceTable = (props) => {
  const { onPageChange, page, data,  } = props

  const handleDelete = useCallback(id => {
    updateUserHealthAccessTokenPromise({ id, active: false })
      .then(() => window.location.reload())
  }, [])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {((data && data.rows) || []).map(record => (
                    <TableRow key={record.id} hover>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.user_id}</TableCell>
                      <TableCell>{record.source_name}</TableCell>
                      <TableCell>
                        {
                          checkPermission('120402') && <DeleteBtn onClick={event => handleDelete(record.id)} />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPage={10}
          count={data.count || 0}
          rowsPerPageOptions={[10]}
          onPageChange={onPageChange}
        />
      </CardActions>
    </Card>
  )
}

export default VideoResourceTable
