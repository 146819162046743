import React, { useLayoutEffect, useState } from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { convertMapToOption } from 'helpers/utils'

export default function Toolbar (props) {
  const { params, handleSearchChange, types } = props
  const [typeOptions, setTypeOptions] = useState([])

  useLayoutEffect(() => { 
    setTypeOptions(convertMapToOption(types))
  }, [types])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField name='value' label='Value' fullWidth variant='outlined' size='small' className='background-color' value={params.value} onChange={event => handleSearchChange({value: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={3}>
          <AutoCompleteNew name='whitelist-autocomplete' label='Type' disableUnderline={false} value={parseInt(params.type)} options={typeOptions} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => handleSearchChange({type: selected && selected.value})}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={1} container justifyContent='flex-end'>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push(`/whitelist/add`)
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
