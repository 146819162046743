import { options } from 'superagent'
import { AutoCompleteNew } from 'components/Select'
import { createWhitelistPromise } from 'api/Whitelist'
import { Button, Card, Grid } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

export default function (props) {
  const { history } = props
  const columnsNotNull = ['type', 'value']
  const [data, setData] = useState({ type: 1 })

  const handleChange = useCallback((key, value, other) => {
    setData({ ...data, [key]: value, ...other })
  }, [data])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    const values = data.value.split(',').map(_.trim)
    createWhitelistPromise(values.map(value => ({ type: data.type, value })))
      .then(() => history.goBack())
  }, [data, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!data[cname]) return true
  }, [data, columnsNotNull])

  const textareaStyle = useMemo(() => {
    return { borderColor:'#cbcbcb', borderRadius: '4px', minHeight: '5vh', maxHeight: '80vh', padding: '1em' }
  }, [])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          Add Whitelist
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <AutoCompleteNew enumName='AccessLogAndWhitelistTypes' name='type' variant='outlined' inputLeft={true} placeholder='type'
                    options={options} value={data.type} onChange={(event, selected) => handleChange('type', selected?.value)} />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className='form-input-elem'>
                  <textarea className='full-width' style={textareaStyle} placeholder='ip1,ip2,...'
                    value={data.value} onChange={e => handleChange('value', e.target.value)} />
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
